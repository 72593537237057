.icon {
  transition: transform 0.2s ease;
}

.extraInfo[data-hidden-when-expanded='true'] {
  @apply duration-200 ease-in-out;
  transition-property: max-height, margin, opacity;
}

.button[aria-expanded='true'] + .extraInfo[data-hidden-when-expanded='true'] {
  @apply m-0 opacity-0 pointer-events-none max-h-0;
}
