.transition {
  @apply duration-200 delay-200;
  transition-property: padding-bottom;
}

.bar {
  padding-bottom: calc(1.25rem + env(safe-area-inset-bottom));
}

.stickyBar {
  bottom: -1px;
}

/* Loading State */

.bar[data-loading='true'] div,
.bar[data-loading='true'] button {
  @apply relative;
}

.bar[data-loading='true'] div:after, 
.bar[data-loading='true'] button:after {
  content: '';
  @apply absolute inset-0 bg-gray-200 rounded-sm pointer-events-none;
}


.bar[data-loading='true'] div:after {
  @apply w-32;
}


.bar[data-loading='true'] button:after {
  @apply bg-green-800;
}


