.floatingBox {
  @apply flex flex-col items-center justify-between p-4 overflow-y-auto lg:overflow-visible;
  @apply lg:items-start lg:justify-start md:p-8 lg:p-0 lg:pl-10 lg:border-l lg:border-gray-500/10;
  @apply lg:bg-transparent lg:shadow-none lg:rounded-none !important;
}

.scrollContent {
  @apply flex flex-col w-full h-full;
  min-height: min-content;
}

.closeButtonContainer {
  @apply absolute top-4 right-4 lg:hidden;
}
