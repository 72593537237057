.carouselWrapper {
  @apply self-center w-screen h-full pt-6;
  @apply md:w-auto md:col-content md:pt-12 md:pb-10;
  @apply lg:h-auto lg:pt-0 lg:pb-10;
}

.title {
  @apply mb-4 text-white text-center;
}

.title span {
  @apply hidden md:inline;
}

.photo {
  @apply w-full h-full object-contain;
  @apply lg:h-screen;

  max-height: 75vh;
}

.reviewContent {
  @apply relative h-screen;
  @apply md:grid md:gap-3;
  @apply lg:h-auto;

  height: calc(100vh - 4rem);
}

@screen md {
  .reviewContent {
    grid-template-rows: 50vh 1fr;
    height: calc(100vh - 8rem);
  }
}

@screen lg {
  .reviewContent {
    @apply h-auto grid-rows-none;
    grid-template-columns: 1fr 21.5rem;
  }
}

.reviewContent figure {
  @apply h-full bg-white flex items-center pb-24;
  @apply md:bg-gray-850 md:pb-0 md:h-auto md:rounded;
}

.reviewDetails {
  @apply overflow-hidden grid bg-white transition-all duration-300;
  @apply absolute bottom-0 left-0 right-0 rounded-t-xl;
  @apply md:h-auto md:static md:rounded md:border md:border-gray-200;

  box-shadow: 0 0 0.4rem rgb(0 0 0 / 20%);
  grid-template-rows: min-content 1fr;
  height: 7.5rem;
}

.reviewDetails[data-expanded='true'] {
  @apply h-2/5;
  @apply md:h-auto;
}

.toggleIcon {
  @apply absolute top-0.5 right-0.5 rotate-90 w-10 h-10 p-2 transition-all duration-300;
  @apply md:hidden;
}

.reviewDetails[data-expanded='true'] .toggleIcon {
  @apply -rotate-90;
}

@screen md {
  .reviewDetails {
    box-shadow: none;
    height: auto;
    max-height: calc(50vh - 8.75rem);
  }
}

@screen lg {
  .reviewDetails {
    max-height: 75vh;
  }
}

.renterProfile {
  @apply p-4 pb-1 grid gap-4;
  @apply md:px-6 md:py-5 md:border-b md:border-gray-200;

  grid-template-columns: calc(3rem - 3px) 1fr;
}

.renterProfile > div:first-child {
  @apply w-10 h-10;
}

.renterProfile > div {
  @apply flex flex-row flex-wrap;
}

.renterProfile b {
  flex: 0 0 100%;
}

.renterProfile span {
  @apply mr-2 text-sm;
}

.reviewText {
  @apply line-clamp-2 overflow-hidden h-10 px-4 text-gray-500 text-sm;
  @apply md:h-auto md:p-6 md:overflow-auto md:text-base md:whitespace-normal;
}

.reviewDetails[data-expanded='true'] .reviewText {
  @apply py-2 line-clamp-none overflow-auto h-auto;
}

.reviewAvatar {
  @apply border-0;
}
