.rules {
  @apply md:columns-2 w-full;
}

.rules li:not(:last-child) {
  @apply mb-4;
}

.rules li:last-child {
  @apply mb-4 md:mb-0;
}
