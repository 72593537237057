
.container[data-loading='true'] button *,
.container[data-loading='true'] a * {
  @apply hidden;
  font-size: 0;
}


.container[data-loading='true'] a {
  @apply w-44 h-12;
}

.container[data-loading='true'] button {
  @apply w-12 h-12;
}
  
