.titleWrap {
  hyphens: auto;
  overflow-wrap: break-word;
}

/* Loading State */

.listingHeader[data-loading='true'] {
    @apply pointer-events-none relative h-48;
}


.listingHeader[data-loading='true']:before,
.listingHeader[data-loading='true'] .title:after,
.listingHeader[data-loading='true'] .title:before {
    content: '';
    @apply absolute inset-0 z-20 transition-opacity duration-200 bg-gray-200 rounded-sm pointer-events-none;
}

.listingHeader[data-loading='true']:before {
    @apply bg-white z-10;
}

.listingHeader[data-loading='true'] .title {
    @apply relative h-14 md:h-20;
    max-width: 85%;
}

.listingHeader[data-loading='true'] .title:after,
.listingHeader[data-loading='true'] .title:before {
    height: 50%;
}

.listingHeader[data-loading='true'] .title:after {
    transform: translateY(110%);
    max-width: 70%;
}

.listingHeader[data-loading='true'] .tag {
    @apply h-10 z-30 w-32 relative overflow-hidden;
    font-size: 0;
}

.listingHeader[data-loading='true'] .tag:after {
    content: '';
    background: inherit;
    @apply absolute inset-0;
}


.listingHeader[data-loading='true'] .tag * {
    font-size: 0;
}

.listingHeader[data-loading='true'] .tags:nth-child(2) .tag {
    @apply w-28;
}

.listingHeader[data-loading='true'] .tags:nth-child(4) .tag {
    @apply w-44;
}
