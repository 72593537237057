.carouselLargeGrid {
  @apply grid-cols-5;
}

.carouselGrid > div,
.carouselLargeGrid > div {
  @apply w-64;
}

.carouselGrid {
  grid-template-columns: repeat(16, 1fr);
}

.carouselGrid.verticalScrollGrid {
  @apply grid-cols-1;
}

.carouselGrid.verticalScrollGrid > div {
  @apply w-full;
}

@screen sm {
  .carouselGrid.verticalScrollGrid {
    @apply grid-cols-2;
  }
}

@screen md {
  .carouselGrid {
    @apply grid-cols-3;
  }
  .carouselGrid > div {
    @apply w-full;
  }
  .carouselLargeGrid > div {
    @apply w-64;
  }
  .carouselInline,
  .carouselLargeGrid {
    grid-template-columns: repeat(5, 1fr);
    @apply overflow-x-scroll;
  }
  .carouselGrid.verticalScrollGrid {
    @apply grid-cols-3;
  }
}

@screen lg {
  .carouselLargeGrid,
  .carouselGrid {
    @apply grid-cols-4 overflow-x-hidden;
  }
  .carouselGrid.verticalScrollGrid {
    @apply grid-cols-4;
  }
  .carouselLargeGrid > div {
    @apply w-full;
  }
  .carouselLargeGrid > div[data-testid='listing-tile']:last-child {
    display: none;
  }
}

@screen xxl {
  .carouselLargeGrid {
    @apply grid-cols-5;
  }
  .carouselLargeGrid > div[data-testid='listing-tile']:last-child {
    display: block;
  }
}
