.CodeInput {
  display: flex;
}

.CodeInput > * {
  margin-left: 0.5rem;
}

.CodeInput > *:first-child {
  margin-left: 0;
}

.TextInput {
  -moz-appearance: textfield;
}

.TextInput::-webkit-inner-spin-button, .TextInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
