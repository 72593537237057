.container {
  grid-template-columns: 1fr;
  grid-template-areas: 'image-1';
}

.container a:hover img,
.container a:focus img {
  @apply scale-105;
}

.item:nth-child(1) {
  grid-area: image-1;
}

.item:nth-child(2) {
  display: none;
  grid-area: image-2;
}

.item:nth-child(3) {
  display: none;
  grid-area: image-3;
}

.item:nth-child(4) {
  display: none;
  grid-area: image-4;
}

@screen md {
  .container[data-children='1'] {
    grid-template-columns: 1fr;
    grid-template-areas: 'image-1';
  }

  .container[data-children='2'] {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'image-1 image-2';
  }

  .container[data-children='2'][data-split-even='true'] {
    grid-template-columns: 1fr 1fr;
  }

  .container[data-children='3'],
  .container[data-children='4'] {
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'image-1 image-2'
      'image-1 image-3';
  }

  .item:nth-child(2) {
    display: block;
  }

  .item:nth-child(3) {
    display: block;
  }
}

@screen lg {
  .container[data-children='4'] {
    grid-template-columns: 50% 1fr 1fr;
    grid-template-areas:
      'image-1 image-2 image-4'
      'image-1 image-3 image-4';
  }

  .item:nth-child(4) {
    display: block;
  }
}

/* Loading */

.container[data-loading='true'] {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

@screen md {
  .container[data-loading='true'] {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'image-1 image-2'
      'image-1 image-3';
  }
}

@screen lg {
  .container[data-loading='true'] {
    grid-template-columns: 3fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'image-1 image-2 image-3';
  }
}



