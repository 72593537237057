.grid {
  --grid-col-offset: 0;
}

@media (min-width: theme('screens.md')) {
  .allStar {
    transform: translateY(25%);
  }

  .memberSince {
    @apply self-start mt-2;
  }

  .allStar + .memberSince {
    @apply self-center;
  }
}



/* Loading State */

.grid[data-loading='true'] {
  @apply pointer-events-none relative h-60;
  font-size: 0;
}

.grid[data-loading='true']:before,
.grid[data-loading='true'] .title:before,
.grid[data-loading='true'] .title:after,
.grid[data-loading='true'] .avatar:after,
.grid[data-loading='true'] .description,
.grid[data-loading='true'] .description:after,
.grid[data-loading='true'] .description:before,
.grid[data-loading='true'] .tag:after,
.grid[data-loading='true'] .footer button,
.grid[data-loading='true'] .footer:after,
.grid[data-loading='true'] .ctaContent,
.grid[data-loading='true'] .ctaContent:after {
  content: '';
  @apply absolute inset-0 z-20 transition-opacity duration-200 bg-gray-200 rounded-sm pointer-events-none;
}

.grid[data-loading='true']:before {
  @apply bg-white z-10;
}

.grid[data-loading='true'] .avatar {
  @apply relative w-full h-full block rounded-full overflow-hidden -mt-2 md:mt-0;
}

.grid[data-loading='true'] .tag {
  @apply hidden md:block relative h-4 w-full block mt-5;
}

.grid[data-loading='true'] .title {
  @apply relative h-6 md:h-8 -mb-8;
  max-width: 50%;
}

@media (max-width: 767px) {
  .grid[data-loading='true'] .title:after {
    transform: translateY(115%);
    max-width: 90%;
  }
}

.grid[data-loading='true'] .description {
  @apply relative h-4 md:h-5;
  max-width: 95%;
}

.grid[data-loading='true'] .description:after {
  transform: translateY(115%);
  max-width: 100%;
}

.grid[data-loading='true'] .description:before {
  transform: translateY(-115%);
  width: 105%;
}

.grid[data-loading='true'] .footer {
  @apply relative;
}

.grid[data-loading='true'] .footer:after {
  @apply hidden md:block h-5 -mt-9 w-24;
  transform: translateY(-100%);
}


.grid[data-loading='true'] .footer button {
  @apply relative h-12 w-full md:w-48 bg-green-800 rounded-md;
  font-size: 0;
}

.grid[data-loading='true'] .ctaContent {
  @apply relative h-3.5 w-44 md:w-24 mt-4 md:-mt-4 transform ml-20 md:ml-2;
  font-size: 0;
}

.grid[data-loading='true'] .ctaContent:after {
  @apply hidden md:block w-28;
  transform: translateY(115%);
}