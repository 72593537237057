.policyCentent {
  @apply mt-1 text-base semiHighlight mb-6 md:mb-1;
}

.policyCentent[data-modal="true"] > p {
  @apply my-4
}

.policyCentent[data-modal="true"] > p:first-of-type {
  @apply mt-2 mb-4
}

.policyCentent > ul {
  @apply list-disc list-outside ml-4
}


