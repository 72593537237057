/* TODO: Fix lopsided alignments of Sofia font  */

/* Types */

.tag[data-color='primary'][data-weight='normal'] {
  @apply bg-canvas-200 text-gray-850;
}

.tag[data-color='gray'][data-weight='bold'] {
  @apply bg-gray-900 text-white;
}

.tag[data-color='yellow'][data-weight='bold'] {
  @apply bg-yellow-600 text-gray-900;
}
/* Sizes */

.tag[data-size='small'] {
  @apply py-1.5 px-3 text-100;
}

.tag[data-size='medium'] {
  @apply py-2 px-3 text-100;
}

.tag[data-size='large'] {
  @apply py-3 px-5 text-200;
}

/* Behavior */

.tag[data-clickable='true'] {
  @apply cursor-pointer;
}

.tag[data-clickable='true'][data-color='primary'] {
  @apply hover:bg-primary-800 hover:text-primary-contrast;
}
