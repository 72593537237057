.item {
  @apply text-gray-900;
  outline-offset: theme('spacing.1');
  outline-color: theme('colors.green.900');
}

.separator {
  @apply inline-block align-text-top mx-2;
  color: theme('colors.green.800');
}
