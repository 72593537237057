.bar[data-visible='true'] {
  @apply translate-y-0;
}

.booker[data-visible='true'] {
  @apply translate-y-0;
}

.link[data-current='true'] {
  @apply text-gray-900 underline;
}
