.rules {
  @apply md:w-1/2;
}

.rules li:not(:last-child) {
  @apply mb-4;
}

.rules li:last-child {
  @apply mb-4 md:mb-0;
}
