.wrapper {
  @apply mb-8 md:mb-12;
}

.title {
  @apply autoType800 highlight mb-5 text-lg leading-5;
}

.photosList {
  @apply grid grid-flow-col gap-4 overflow-auto pb-4;
  @apply md:block md:pb-0 md:overflow-visible;

  grid-auto-columns: 8.75rem;
}

.photosList div[data-variant='outside'][data-direction='right'] {
  @apply p-0 translate-x-2;
}

.photosList div[data-variant='outside'][data-direction='left'] {
  @apply p-0 -translate-x-2/4;
}

.photo {
  @apply rounded-md cursor-pointer;
}
